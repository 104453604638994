<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !contacts }">
		<v-preloader v-if="!contacts"></v-preloader>

		<div class="container news__container" v-if="contacts">
			<div class="news__all__list">
				<p class="news__top__link mr-4 pointer" @click="$router.push('/' + $i18n.locale)">
					{{ $t('site.home_page') }}
				</p>
				<p class="news__top__link news__link__active pointer">{{ $t('site.menu.contact') }}</p>
			</div>

			<div class="contact-page__content">
				<br />
				<div class="section__title">
					{{ $t('site.menu.contact') }}
				</div>

				<div class="contact-page__block">
					<div class="contact__block--info">
						<div class="row">
							<div class="col-lg-4 contact__col--col" v-if="contacts.address">
								<div class="contact__col">
									<div class="contact__col--icon">
										<svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M46.375 22.084c0 15.458-19.875 28.708-19.875 28.708S6.625 37.542 6.625 22.083a19.875 19.875 0 1139.75 0z" stroke="#52A5FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M26.5 28.709a6.625 6.625 0 100-13.25 6.625 6.625 0 000 13.25z" stroke="#52A5FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
									</div>
									<div class="contact__col--info">
										<div class="contact__col--title">
											{{ $t('site.page.contact.address') }}
										</div>
										<div class="contact__col--desc">
											<div v-for="(address, addressInd) in contacts.address" :key="addressInd">
												<span v-if="$i18n.locale == 'ru'">{{ address.text_ru }}</span>
												<span v-if="$i18n.locale == 'en'">{{ address.text_en }}</span>
												<span v-if="$i18n.locale == 'kz'">{{ address.text_kz }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 contact__col--col" v-if="contacts.phone">
								<div class="contact__col">
									<div class="contact__col--icon">
										<svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M48.584 37.365v6.625a4.418 4.418 0 01-4.815 4.417 43.702 43.702 0 01-19.057-6.78 43.062 43.062 0 01-13.25-13.25 43.703 43.703 0 01-6.78-19.146 4.417 4.417 0 014.395-4.814h6.625a4.417 4.417 0 014.416 3.798c.28 2.12.798 4.202 1.546 6.206a4.417 4.417 0 01-.994 4.66l-2.804 2.804a35.334 35.334 0 0013.25 13.25l2.804-2.805a4.416 4.416 0 014.66-.994 28.356 28.356 0 006.205 1.546 4.417 4.417 0 013.799 4.483z" stroke="#52A5FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
									</div>
									<div class="contact__col--info">
										<div class="contact__col--title">
											{{ $t('site.page.contact.phone') }}
										</div>
										<div class="contact__col--phone">
											<div v-for="element in contacts.phone" :key="element.link_ru">
												<a v-if="$i18n.locale == 'ru'" :href="'tel:' + element.link_ru">{{
													element.text_ru
												}}</a>
												<a v-if="$i18n.locale == 'kz'" :href="'tel:' + element.link_kz">{{
													element.text_kz
												}}</a>
												<a v-if="$i18n.locale == 'en'" :href="'tel:' + element.link_en">{{
													element.text_en
												}}</a>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 contact__col--col" v-if="contacts.mail">
								<div class="contact__col">
									<div class="contact__col--icon">
										<svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M26.5 35.333a8.833 8.833 0 100-17.666 8.833 8.833 0 000 17.666z" stroke="#52A5FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M35.333 17.667v11.041a6.625 6.625 0 0013.25 0V26.5a22.083 22.083 0 10-8.657 17.534" stroke="#52A5FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
									</div>
									<div class="contact__col--info">
										<div class="contact__col--title">
											{{ $t('site.page.contact.mail') }}
										</div>
										<div class="contact__col--phone">
											<div v-for="element in contacts.mail" :key="element.link_ru">
												<a v-if="$i18n.locale == 'ru'" :href="'tel:' + element.link_ru">{{
													element.text_ru
												}}</a>
												<a v-if="$i18n.locale == 'kz'" :href="'tel:' + element.link_kz">{{
													element.text_kz
												}}</a>
												<a v-if="$i18n.locale == 'en'" :href="'tel:' + element.link_en">{{
													element.text_en
												}}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="contact__block--map" v-if="contacts.map"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data() {
		return {
			contacts: null,
		}
	},
	methods: {
		getContacts() {
			api
				.get('contacts', {})
				.then((response) => {
					this.contacts = response.data
				})
				.catch(() => {})
		},
	},
	mounted() {
		this.getContacts()
	},
}
</script>

<style scoped></style>
